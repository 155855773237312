<template>
  <div class="Login">
   <Login/>
  </div>
</template>

<script>
import Login from "../components/login.vue";


export default {
    components: { Login }
}
</script>
<style lang="scss" scoped>

</style>
